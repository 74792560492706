import React from 'react'
import { Link } from 'gatsby'

// Components
import Layout from '../components/Layout'
import Seo from '../components/Seo'

// React Icons
import { FaGalacticRepublic, FaArrowRight, FaHome } from 'react-icons/fa'

import trooper from '../../assets/stormtrooper.png'

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title={`Error`} />
      <header className="masthead">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <div className="page-heading">
                <h1>404</h1>
                <span className="subheading">Ut oh, something went boom boom</span>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto">
            <div className="stormy">
              <img className="img-fluid" src={trooper} alt="Storm Trooper" />
            </div>
            <p>
              There appears to have been an issue in your request. May I suggest a speedy getaway{' '}
              <Link to="/">home</Link>?
            </p>
            <p>
              <Link to="/">
                <FaGalacticRepublic /> <FaArrowRight /> <FaHome />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
